.card {
  background-color: #f8f8f8;
  border-radius: 10px;
  min-height: 250px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  width: 100%;
  max-width: 300px;
}

.wrapImage {
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgb(0, 0, 0);
  font-weight: bold;
  margin-top: 20px;
}

.image {
  width: 100%;
  height: 100%;
  padding: 10px;
  object-fit: contain;
}
.imageBox {
  background-color: #fff;
  border-radius: 1vw 1vw 0 0;
  width: 220px;
  height: 220px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.imageLink {
  cursor: pointer;
}
.textMain {
  position: absolute;
  background: rgba(61, 61, 61, 0.48);
  border-radius: 3px;
  color: #fff;
  font-size: 10px;
  line-height: 13px;
  padding: 1px 4px 2px;
  text-align: center;
  top: 10px;
  right: 10px;
  text-decoration: underline;
}
.items {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgb(0, 0, 0);
  font-weight: bold;
}
.iconsvg:hover {
  fill: red;
}

@media (max-width: 1150px) {
  .textMain {
    right: 24px;
  }
}

@media (max-width: 350px) {
  .textMain {
    right: 14px;
  }
}
