.banner {
  object-fit: cover;
  width: 100%;
}

.ad {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cards {
  display: grid;
  gap: 2vw;
  grid-template-columns: repeat(4, 1fr);
}
.reklama {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 30%;
}

.items {
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 500;
}

.wrapper {
  background: #000;
  color: #fff;
  opacity: 0.2;
  border-radius: 1rem;
  position: relative;
}

.closeButton {
  top: 0;
  right: 0;
  position: absolute;
}

.popupCloseBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: #222222;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  z-index: 1;

  &::after,
  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;

    width: 1rem;
    height: 0.125rem;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.picture {
  align-items: flex-start;
  display: flex;
  width: 100%;
  position: relative;
}

.text {
  position: absolute;
  background: rgba(61, 61, 61, 0.48);
  border-radius: 3px;
  color: #fff;
  font-size: 10px;
  line-height: 13px;
  padding: 1px 4px 2px;
  text-align: center;
  top: 10px;
  right: 0;
  text-decoration: underline;
}

.textMain {
  position: absolute;
  background: rgba(61, 61, 61, 0.48);
  border-radius: 3px;
  color: #fff;
  font-size: 10px;
  line-height: 13px;
  padding: 1px 4px 2px;
  text-align: center;
  top: -16px;
  right: 0;
  text-decoration: underline;
}

.hashtag {
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
}

.title {
  font-size: 2.375rem;
  font-weight: 700;
  line-height: 1.18;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin: auto 0 0.625rem;
}

.text {
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  max-width: 29rem;
  margin-bottom: 1.4rem;
}

.image {
  border-radius: 0.625rem;
  filter: drop-shadow(0px 1.25rem 1.25rem rgba(0, 0, 0, 0.25));
}

@media (max-width: 1150px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 991px) {
  .image {
    display: none;
  }

  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .closeButton {
    right: 20%;
  }

  .title {
    font-size: 1.75rem;
  }

  .picture {
    max-width: 20rem;
  }

  .ad {
    max-width: 20rem;
  }

  .popupCloseBtn {
    top: 25px;
  }

  .reklama {
    top: 40%;
  }
}

@media (max-width: 600px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-items: center;
  }
}
